/* Import fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@400;700&display=swap');

/* Basic reset and global styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Body font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0; /* Light grey background */
  color: #333; /* Dark grey text */
}

/* Specific styles for headers */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif; /* Header font */
}

h1 {
  font-weight: 700; /* Bold headers */
}

h2, h3, h4, h5, h6 {
  font-weight: 400; /* Regular headers */
}

/* Optional: Customize the appearance of code blocks */
pre, code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f5f5f5;
  padding: 0.5rem;
  border-radius: 4px;
}

/* Styles for links */
a {
  color: #1e90ff; /* Link color */
  text-decoration: none; /* Remove underline */
}

a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Styles for buttons */
button {
  font-family: 'Roboto', sans-serif; /* Button font */
  font-weight: 700;
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition for hover effects */
}

button:hover {
  background-color: #0056b3; /* Darker button color on hover */
}

button:focus {
  outline: 2px solid #0056b3; /* Visible focus style */
  outline-offset: 2px; /* Space between outline and element */
}

/* Container and layout styles */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

/* Flexbox alignment for central content */
.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Responsive design tweaks */
@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0.5rem;
  }
}
